import styled from 'styled-components'
import DatePicker from "react-datepicker";
//import TextareaAutosize from 'react-textarea-autosize';
import {NumericFormat} from 'react-number-format';
export const Wrapper = styled.div`
width:100%;
position:${ props => props.position || "relative"};

`;
export const Flexed = styled.div`
display: ${props => props.display || "flex"};
justify-content: ${props => props.justifyContent || ""};
flex-direction: ${props => props.flexDirection || "row"};
align-items: ${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
width: ${props => props.width || "100%"};
padding: ${props => props.padding || "0px"};
background-color: ${props => props.bgColor || ""};
border: ${props => props.border || ""};
gap: ${props => props.gap || "5px"};
font-size:${props => props.fontSize || ""};
font-weight:${props => props.fontWeight || ""};
margin: ${props => props.margin || "0px"};
height: ${props => props.height || ""};
border-bottom: ${props => props.borderBottom || ""};
border-top: ${props => props.borderTop || ""};
color: ${props => props.color || ""};
padding: ${props => props.padding || "0px"};
top: ${props => props.top || ""};
bottom: ${props => props.bottom || ""};
right:${props => props.right || ""};
text-align: ${props => props.textAlign || ""};
letter-spacing:${props => props.letterSpacing || ""};
margin-bottom: ${props => props.marginBottom || "0px"};
margin-top: ${props => props.marginTop || "0px"};
`;

export const Flex = styled.div`
flex: ${props => props.flex || "1"};
width: ${props => props.width || "100%"};

justify-content: ${props => props.justifyContent || ""};
align-items: ${props => props.alignItems || ""};
text-align: ${props => props.textAlign || ""};
height: ${props => props.height || ""};
padding: ${props => props.padding || "0px 3px"};
position:${props => props.position || "relative"};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
color: ${props => props.color || ""};
border-bottom: ${props => props.borderBottom || ""};
border-right: ${props => props.borderRight || ""};
border-left: ${props => props.borderLeft || ""};
border-top: ${props => props.borderTop || ""};
border-bottom: ${props => props.borderBottom || ""};
min-width:${props => props.minWidth || ""};
font-size:${props => props.fontSize || "inherit"};
font-weight:${props => props.fontWeight || ""};
margin: ${props => props.margin || "0px"};
word-break:${props => props.wordBreak || "normal"};
overflow-wrap:normal;
bottom: ${props => props.bottom || "0px"};
display: inline-flex;
flex-shrink:${props => props.flexShrink || "0"};
flex-grow:${props => props.flexgrow || "auto"};

word-break: break-all;
`;

export const Input = styled.input`
position: ${props => props.position || "relaitve"};
font-size:${props => props.fontSize || "calc(.9rem + .2vw)"};
border: ${props => props.border || "1px solid #E2E2E2"};
outline: ${props => props.outline || "none"};
width: ${props => props.width || "auto"};
padding: ${props => props.padding || "5px"};
&:focus{
  border:1px solid #5D6F87;
}

`;

export const Box = styled.input`


`;

export const Img = styled.img`
position: ${props => props.position || "relative"};
width: ${props => props.width || "100%"};
height: ${props => props.height || ""};
min-height: ${props => props.minHeight || ""};
max-height: ${props => props.maxHeight || ""};
color: ${props => props.color || ""};
object-fit: cover;


`;

export const Label = styled.label`
font-size:${props => props.fontSize || "calc(.8rem + .3vw)"};

font-weight:${props => props.fontWeight || ""};

`;

export const Selectbox = styled.select`
position: ${props => props.position || "relaitve"};
font-size:${props => props.fontSize || "calc(.9rem + .2vw)"};
border: ${props => props.border || "1px solid #E2E2E2"};
outline: ${props => props.outline || "none"};
width: ${props => props.width || "auto"};
padding: ${props => props.padding || "5px"};

  option {
        font-size:calc(.9rem + .2vw);
       }

       
&:focus{
  border:1px solid #5D6F87;
}


`;


export const Button = styled.button`
position: ${props => props.position || "relaitve"};
right:30px;
border-radius:5px;
border: ${props => props.border || "none"};
outline:none;
font-weight:bold;
padding:${props => props.padding || "7px 20px"};
background-color: ${props => props.backgroundColor || "#78BE20"};
font-size:calc(.7rem + .2vw);
word-break: keep-all;
color:white;

&:hover{
  background-color: ${props => props.hbackgroundColor || "white"};
  color:#78BE20;
  border:1px solid #78BE20;
  padding:6px 20px;
}
`;


export const Datepick = styled(DatePicker)`
position:relative;
border-radius:5px;
width: ${props => props.width || "100%"};
border:1px solid rgb(0,0,0,.3);
outline: none;
font-size:calc(1rem + .2vw);
font-weight:${props => props.fontWeight || ""};
padding:8px 5px;
height:30px;
&:focus{
  border:2px solid var(--plsGreen);
}
`;


export const PhoneNumber = (value) => {
  if(!value || value === undefined  ){
  return value;
      }else{
          value = value.replace(/[^\d]/g, '');
          return `(${value.slice(0,3)}) ${value.slice(3,6)} - ${value.slice(6,10)}`;
      }
  
  }


  
export const currency = (num) => {
  return parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const CurrencyInput = styled(NumericFormat)`
position: ${props => props.position || "relaitve"};
font-size:${props => props.fontSize || "calc(.9rem + .2vw)"};
border: ${props => props.border || "1px solid #E2E2E2"};
outline: ${props => props.outline || "none"};
width: ${props => props.width || "auto"};
padding: ${props => props.padding || "5px"};
&:focus{
  border:1px solid #5D6F87;
}
`;












//https://app.highgroundsoftware.com/information?cid=64f8ca2df9ff7f3e29a89ed7&qid=64f8ca2df9ff7f3e29a89ed7