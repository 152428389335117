import React from 'react'
import Information from './Information'
import { Route, Routes } from 'react-router-dom'
import Customers from './Customers'
import CreditApp from '../pages/CreditApp'
import Confetti from './Thanks'

const Dashboard = () => {
  return (
    <div>

        <Routes>
            <Route path="/information" element={<Information />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/creditapp" element={<CreditApp />} />
            <Route path="/thanks" element={<Confetti />} />
            <Route path="*" element={<Information />} />
        </Routes>
    </div>
  )
}

export default Dashboard
