import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Flexed, Img, Input, Label, Selectbox, PhoneNumber, Datepick, currency,CurrencyInput} from '../utilities/Shared'
import States from '../utilities/States'
import axios from 'axios'
import LOGO from '../Assets/plsprint.png'
import { useParams, useSearchParams } from 'react-router-dom'
import Fileupload from '../components/Fileupload'
import Moment from 'react-moment';
import { v4 as uuidv4 } from 'uuid';
import { defaultBanks, defaultOwners, defaultRefers } from '../utilities/Defaults'
import "react-datepicker/dist/react-datepicker.css";






const Credit = () => {

const [customer, setCustomer] = useState({})
const [location, setLocation] = useState({state:"FL",billing:"No"})
const [locations, setLocations] = useState([])
const [billingaddress, setBillingaddress] = useState({})
const [contact, setContact] = useState({})
const [contacts, setContacts] = useState([])
const [billingcheck, setBillingcheck] = useState(false)
const date = new Date().toLocaleDateString()
const [taxexempt, setTaxexempt] = useState()
const [file, setFile] = useState(null)
const informationid = '64f8ca2df9ff7f3e29a89fbb'
const [urlinfo] = useSearchParams()

const [mainlocation, setMainlocation] = useState({})
const [maincontact, setMaincontact] = useState({})
const [owners, setOwners] = useState([])
const [currentowner, setCurrentowner] = useState({})
const [banks, setBanks] = useState([])
const [currentbank, setCurrentbank] = useState({})
const [refers, setRefers] = useState([])
const [currentrefer, setCurrentrefer] = useState({})
const [creditinfo, setCreditinfo] = useState({amount:0})
const [sigdate, setSigdate] = useState(null)

const defaultbilling = {
    name:null,
    dba:null,
    address1:null,
    address2:null,
    city:null,
    state:null,
    zip:null
}
const defaultlocation = {
        address1:"",
        address2:"",
        billing:"",
        city:"",
        state:"FL",
        name:"",
        zip:"",
        itemid:""
}
const defaultcontact = {
    name:"",
    title:"",
    email:"",
    phone:"",
    mobile:"",
    invoiced:"",
    contactid:""
}
useEffect(() => {
    let id = {
        supplyid:urlinfo.get('cid')
    }
    console.log(id)
const getcustomer = () => {
    axios.get(`https://highgroundapi.herokuapp.com/customers/item/${id}`)
    .then(res => {
            setCustomer(res.data);setvalues(res.data)
    })
    .catch(err => console.log(err))
}


getcustomer()
},[])


const setvalues = (customer) => {
    setLocations(customer?.address)
    setContacts(customer?.contacts)
let mlocation = customer.address?.filter(item => item.itemid === customer.mainlocation)
setMainlocation(mlocation[0])
let mcontact = customer.contacts?.filter(item => item.contactid === customer.maincontact)
setMaincontact(mcontact[0])

}

useEffect(() => {

if(billingcheck === true){
  return  setBillingaddress({name:customer.name,dba:customer.dba,address1:location.address1,address2:location.address2,city:location.city,state:location.state,zip:location.zip})
}else{
    return setBillingaddress(defaultbilling)
}

},[billingcheck,customer])

useEffect(() => {
taxexempt === "No" && setFile(null)
},[taxexempt])


console.log(customer)


const addlocation = (e) => {

   let templocations = locations
   templocations.itemid = uuidv4();

    templocations.push(location)
    setLocations(templocations)
    setLocation(defaultlocation)
}

const addcontact = (e) => {

    let tempcontacts = contacts
    tempcontacts.contactid = uuidv4();
 
     tempcontacts.push(contact)
     setContacts(tempcontacts)
     setContact(defaultcontact)
 }


 const addowner = () => {

    setOwners([...owners,currentowner])
    setCurrentowner(defaultOwners)
 }

 const addbank = () => {

    setBanks([...banks,currentbank])
    setCurrentbank(defaultBanks)
 }


 const addrefer = () => {

    setRefers([...refers,currentrefer])
    setCurrentrefer(defaultRefers)
 }


 const handleupload = async() => {
    await axios.post('http://localhost:5003/images/add/tax',file)
    .then(res => console.log(res.data))
    .catch(err => console.log(err))
  }

console.log(currentowner)
console.log(owners)
console.log(banks)
console.log(refers)
const setamount = (amount) => {
let r = (parseInt(amount))
setCreditinfo({...creditinfo, amount:r.toFixed(2)})
};




  return (
    
    <div style={{background:"rgb(0,0,0,0.6)",position:"relative",width:"100%",height:"100%",padding:"0px 0px 50px 0px"}} >
    {customer._id && urlinfo.get('qid') === informationid?
    <div className="information" >

    <form id="some_id" method="post" enctype="multipart/form-data">
<Flexed marginTop="20px">
    <Flex flex=".5"><Img src={LOGO} width="50%" /></Flex>
    <Flex style={{lineHeight:"60px"}} fontSize="calc(1.1rem + .2vw)" fontWeight="bold" textAlign="center">Application for Commercial Credit Form</Flex>
</Flexed>
<Flexed padding="40px 0px 0px 0px">
    <Flex ><Label for="name">Credit Amount Required:&nbsp;</Label><Flex bottom="9px">
    
    <CurrencyInput value={creditinfo.amount} thousandSeparator={true} prefix={'$'} />
       </Flex><span style={{color:"red",fontSize:"2.7rem",display:"flex",position:"absolute",right:"100%",display:"flex",marginTop:"-5px"}}>*</span></Flex>
    <Flex></Flex>
</Flexed>

<Flexed padding="30px 0px 0px 0px">
    <Flex flex="1.5"><Label for="name">Company Name:</Label><Flex bottom="9px"><Input type="text" value={customer?.name} onChange={e => {setCustomer({...customer,name:e.target.value})}} width="100%"  /></Flex></Flex>
    <Flex flex="1.5"><Label for="name">Company DBA:&nbsp;&nbsp;</Label><Flex bottom="9px"><Input type="text" value={customer?.dba} onChange={e => {setCustomer({...customer,dba:e.target.value})}} width="100%"  /></Flex></Flex>
 
</Flexed>


<div >
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Address:</Label>&nbsp;&nbsp;<Flex bottom="9px"><Selectbox  width="100%" value={mainlocation} onChange={e => setMainlocation(e.target.value)}>
        {
            locations.map(item => {
            return   <option value={item.itemid}>{item.address1} {item.address2} {item.city} {item.state}, {item.zip}</option>
            })
        }
        </Selectbox></Flex ><Flex flex=".5"> +</Flex></Flex>
</Flexed>
</div>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Business Phone:</Label><Flex bottom="9px"><Input type="text" value={PhoneNumber(customer?.mainphone)} onChange={e => {setCustomer({...customer,mainphone:e.target.value})}} width="100%"  /></Flex></Flex>
    <Flex flex="1.5"><Label for="name">Website:&nbsp;&nbsp;</Label><Flex bottom="9px"><Input type="text" value={customer?.website} onChange={e => {setCustomer({...customer,website:e.target.value})}} width="100%"  /></Flex></Flex>
    <Flex ><Label for="name"> YIB:</Label><Flex bottom="9px"><Selectbox /></Flex></Flex>
</Flexed>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Business Type:</Label><Flex bottom="9px"><Selectbox width="70%" onChange={e => {setCustomer({...customer,businesstype:e.target.value})}} value={contact?.businesstype}>
    <option value="">Select Type</option>
        <option value="Corporation">Corporation</option>
	<option value="Partnership">Partnership</option>
    <option value="LLC">LLC</option>
	<option value="Sole Proprietorship">Sole Proprietorship</option>
    </Selectbox></Flex></Flex>
    <Flex ><Label for="name">FEIN Number:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer?.fein} onChange={e => {setCustomer({...customer,fein:e.target.value})}} width="100%"  /></Flex></Flex>
    <Flex ><Label for="name">State:</Label><Flex bottom="9px"><States value={customer?.feinstate} onChange={e => {setCustomer({...customer,feinstate:e.target.value})}}/></Flex></Flex>
    </Flexed>


    <Flexed padding="10px 0px 0px 0px">
    <Flex ><Label fontWeight="bold" fontSize="calc(.9rem + .2vw)" for="name">Accounts Payable Contact:</Label>&nbsp;&nbsp;<Flex bottom="9px"><Selectbox  width="100%" onChange={e => {setCustomer({...customer,acctrep:e.target.value})}} value={customer?.acctrep}>
        {
            contacts.map(item => {
            return   <option value={item.contactid} selected={item.contactid === maincontact.contactid ? true : false}>{item.name} - {item.title}   {item.email} </option>
            })
        }
        </Selectbox></Flex ><Flex flex=".2"> +</Flex></Flex>
</Flexed>


<Flexed marginTop="0px">
    <Flex fontSize="calc(.9rem + .2vw)" fontWeight="bold" padding="10px 0px">Ownership Information</Flex>
</Flexed>
<div >
<Flexed bgColor="#78BE20" padding="3px">
<Flex flex="2">Name</Flex>
<Flex flex="2">Title</Flex>
<Flex flex="2">Email</Flex>
<Flex>Phone</Flex>
<Flex>Mobile</Flex>
<Flex>Invoiced</Flex>
<Flex flex=".6"></Flex>
</Flexed>

{owners?.map(owner => {
  return  <Flexed style={{border:"1px solid rgb(0,0,0,.3)"}} padding="3px" fontSize="calc(.9rem + .2vw)" >
<Flex  flex="2" >{owner?.name}</Flex>
<Flex  flex="2">{owner?.title}</Flex>
<Flex  flex="2">{owner?.email}</Flex>
<Flex >{PhoneNumber(owner?.phone)}</Flex>
<Flex >{owner?.mobile}</Flex>
<Flex >{owner?.invoiced}</Flex>
<Flex flex=".6"> p X</Flex>
</Flexed>
})

}
</div>
<div style={{border:"1px solid lightgrey",position:"relative",top:"8px"}}>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Name:</Label><Flex bottom="9px"><Input  autocomplete="new-user-email" type="text" value={currentowner?.name} width="100%" onChange={e => {setCurrentowner({...currentowner,name:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Title:</Label><Flex bottom="9px"><Input  autocomplete="new-user-email" type="text" value={currentowner?.title} width="100%" onChange={e => {setCurrentowner({...currentowner,title:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Email:</Label><Flex bottom="9px"><Input  autocomplete="new-user-email" type="email" value={currentowner?.email} width="100%" onChange={e => {setCurrentowner({...currentowner,email:e.target.value})}}  /></Flex></Flex>

</Flexed>

<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Home Address 1:</Label><Flex bottom="9px"><Input  type="text" autocomplete="new-user-email" value={currentowner?.address1} width="100%" onChange={e => {setCurrentowner({...currentowner,address1:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Home Address 2:</Label><Flex bottom="9px"><Input  autocomplete="new-user-email" type="text" value={currentowner?.address2} width="100%"  onChange={e => {setCurrentowner({...currentowner,address2:e.target.value})}}/></Flex></Flex>

</Flexed>

<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">City:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentowner?.city} width="100%" onChange={e => {setCurrentowner({...currentowner,city:e.target.value})}} /></Flex></Flex>
    <Flex><Label for="name">State:</Label><Flex bottom="9px"><States value={currentowner?.state} onChange={e => {setCurrentowner({...currentowner,state:e.target.value})}}/></Flex></Flex>
    <Flex flex=".5"><Label for="name">Zip:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentowner?.zip} width="100%" onChange={e => {setCurrentowner({...currentowner,zip:e.target.value})}} /></Flex></Flex>
    <Flex flex=".5"></Flex>

</Flexed>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Phone:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={PhoneNumber(currentowner?.phone)} width="100%" onChange={e => {setCurrentowner({...currentowner,phone:e.target.value})}} /></Flex></Flex>
    <Flex flex=".5"></Flex>
    <Flex ><Label for="name">Invoiced :</Label><Flex bottom="9px"><Selectbox value={currentowner?.invoiced} onChange={e => {setCurrentowner({...currentowner,invoiced:e.target.value})}}><option value="No">No</option>
	<option value="Yes">Yes</option></Selectbox></Flex></Flex>
    <Flex flex=".5"></Flex>
    <Flex ><Label for="name">Ownership %:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentowner?.ownerpercent} width="50%" onChange={e => {setCurrentowner({...currentowner,ownerpercent:e.target.value})}} /></Flex></Flex>
   
</Flexed>
<Flexed padding="7px 0px"><Flex><Button type="button" disabled={currentowner?.name === "" || !currentowner?.name ? true : false} onClick={addowner}>Add Owner</Button></Flex></Flexed>
</div>




<Flexed marginTop="30px">
    <Flex fontSize="calc(.9rem + .2vw)" fontWeight="bold" padding="10px 0px">Bank References</Flex>
</Flexed>
<div >
<Flexed bgColor="#78BE20" padding="3px">
<Flex flex="2">Bank Name</Flex>
<Flex>Phone</Flex>
<Flex flex="2">Email</Flex>
<Flex>Account#</Flex>
<Flex>Account Type</Flex>
<Flex>Bank Address</Flex>
<Flex flex=".6"></Flex>
</Flexed>

{banks?.map(bank => {
  return  <Flexed style={{border:"1px solid rgb(0,0,0,.3)"}} padding="3px" fontSize="calc(.9rem + .2vw)" >
<Flex  flex="2">{bank?.name}</Flex>
<Flex  >{bank?.phone}</Flex>
<Flex  flex="2">{bank?.email}</Flex>
<Flex >{bank?.account}</Flex>
<Flex >{bank?.accttype}</Flex>
<Flex >{bank?.address1}</Flex>
<Flex flex=".6"> p X</Flex>
</Flexed>
})

}
</div>
<div style={{border:"1px solid lightgrey",position:"relative",top:"8px"}}>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Bank Name:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentbank?.name} width="100%" onChange={e => {setCurrentbank({...currentbank,name:e.target.value})}} /></Flex></Flex>
    <Flex ></Flex>

</Flexed>

<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Account #:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={currentbank?.account} width="100%" onChange={e => {setCurrentbank({...currentbank,account:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Account Type:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentbank?.accttype} width="100%"  onChange={e => {setCurrentbank({...currentbank,accttype:e.target.value})}}/></Flex></Flex>
</Flexed>
<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Bank Contact:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={currentbank?.contact} width="100%" onChange={e => {setCurrentbank({...currentbank,contact:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Email:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentbank?.email} width="100%"  onChange={e => {setCurrentbank({...currentbank,email:e.target.value})}}/></Flex></Flex>
    <Flex ><Label for="name">Phone #:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={PhoneNumber(currentbank?.phone)} width="100%"  onChange={e => {setCurrentbank({...currentbank,phone:e.target.value})}}/></Flex></Flex>
</Flexed>
<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Bank Address 1:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={currentbank?.address1} width="100%" onChange={e => {setCurrentbank({...currentbank,address1:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Bank Address 2:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentbank?.address2} width="100%"  onChange={e => {setCurrentbank({...currentbank,address2:e.target.value})}}/></Flex></Flex>

</Flexed>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">City:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentbank?.city} width="100%" onChange={e => {setCurrentbank({...currentbank,city:e.target.value})}} /></Flex></Flex>
    <Flex><Label for="name">State:</Label><Flex bottom="9px"><States value={currentbank?.state} onChange={e => {setCurrentbank({...currentbank,state:e.target.value})}}/></Flex></Flex>
    <Flex flex=".5"><Label for="name">Zip:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentbank?.zip} width="100%" onChange={e => {setCurrentbank({...currentbank,zip:e.target.value})}} /></Flex></Flex>
    <Flex flex=".5"></Flex>

</Flexed>

<Flexed padding="7px 0px"><Flex><Button type="button" disabled={currentbank?.name === "" || !currentbank?.name ? true : false} onClick={addbank}>Add Bank</Button></Flex></Flexed>
</div>

<Flexed marginTop="30px">
    <Flex fontSize="calc(.9rem + .2vw)" fontWeight="bold" padding="10px 0px">Trade References (please list 2)</Flex>
</Flexed>
<div >
<Flexed bgColor="#78BE20" padding="3px">
<Flex flex="2">Company Name</Flex>
<Flex flex="2">Contact</Flex>
<Flex flex="2">Email</Flex>
<Flex>Phone</Flex>
<Flex>Address</Flex>
</Flexed>

{refers?.map(refer => {
  return  <Flexed style={{border:"1px solid rgb(0,0,0,.3)"}} padding="3px" fontSize="calc(.9rem + .2vw)" >
<Flex  flex="2">{refer?.name}</Flex>
<Flex  flex="2">{refer?.contact}</Flex>
<Flex  flex="2">{refer?.email}</Flex>
<Flex >{refer?.phone}</Flex>
<Flex >{refer?.address1}</Flex>
</Flexed>
})

}
</div>
<div style={{border:"1px solid lightgrey",position:"relative",top:"8px"}}>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Company Name:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentrefer?.name} width="100%" onChange={e => {setCurrentrefer({...currentrefer,name:e.target.value})}} /></Flex></Flex>
    <Flex ></Flex>

</Flexed>

<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Contact:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={currentrefer?.contact} width="100%" onChange={e => {setCurrentrefer({...currentrefer,contact:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Email:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentrefer?.email} width="100%"  onChange={e => {setCurrentrefer({...currentrefer,email:e.target.value})}}/></Flex></Flex>
    <Flex ><Label for="name">Phone #:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={PhoneNumber(currentrefer?.phone)} width="100%"  onChange={e => {setCurrentrefer({...currentrefer,phone:e.target.value})}}/></Flex></Flex>
</Flexed>
<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Address 1:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={currentrefer?.address1} width="100%" onChange={e => {setCurrentrefer({...currentrefer,address1:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Address 2:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentrefer?.address2} width="100%"  onChange={e => {setCurrentrefer({...currentrefer,address2:e.target.value})}}/></Flex></Flex>

</Flexed>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">City:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentrefer?.city} width="100%" onChange={e => {setCurrentrefer({...currentrefer,city:e.target.value})}} /></Flex></Flex>
    <Flex><Label for="name">State:</Label><Flex bottom="9px"><States value={currentrefer?.state} onChange={e => {setCurrentrefer({...currentrefer,state:e.target.value})}}/></Flex></Flex>
    <Flex flex=".5"><Label for="name">Zip:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={currentrefer?.zip} width="100%" onChange={e => {setCurrentrefer({...currentrefer,zip:e.target.value})}} /></Flex></Flex>
    <Flex flex=".5"></Flex>

</Flexed>
<Flexed padding="7px 0px"><Flex><Button type="button" disabled={currentrefer?.name === "" || !currentrefer?.name ? true : false} onClick={addrefer}>Add Reference</Button></Flex></Flexed>
</div>


<Flexed marginTop="40px">
    <Flex fontSize="calc(.9rem + .2vw)" fontWeight="bold" padding="0px 0px">MORTGAGE HOLDER / LANDLORD INFORMATION</Flex>
</Flexed>
<div style={{border:"1px solid lightgrey",position:"relative",top:"8px"}}>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Company Name:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer?.holder} width="100%" onChange={e => {setCustomer({...customer,holder:e.target.value})}} /></Flex></Flex>
    <Flex ></Flex>

</Flexed>

<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Contact:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={customer.holdercontact} width="100%" onChange={e => {setCustomer({...customer,holdercontact:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Email:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer.holderemail} width="100%"  onChange={e => {setCustomer({...customer,holderemail:e.target.value})}}/></Flex></Flex>
    <Flex ><Label for="name">Phone #:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={PhoneNumber(customer.holderphone)} width="100%"  onChange={e => {setCustomer({...customer,holderphone:e.target.value})}}/></Flex></Flex>
</Flexed>
<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Address 1:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={customer.holderaddress1} width="100%" onChange={e => {setLocation({...location,holderaddress1:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Address 2:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer.holderaddress2} width="100%"  onChange={e => {setLocation({...location,holderaddress2:e.target.value})}}/></Flex></Flex>

</Flexed>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">City:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer.holdercity} width="100%" onChange={e => {setLocation({...location,holdercity:e.target.value})}} /></Flex></Flex>
    <Flex><Label for="name">State:</Label><Flex bottom="9px"><States value={customer.holderstate} onChange={e => {setLocation({...location,holderstate:e.target.value})}}/></Flex></Flex>
    <Flex flex=".5"><Label for="name">Zip:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer.holderzip} width="100%" onChange={e => {setLocation({...location,holderzip:e.target.value})}} /></Flex></Flex>
    <Flex flex=".5"></Flex>

</Flexed></div>
<Flexed padding="30px 0px 0px 0px">
    <Flex flex="1.2"><Label for="name">1. Do you rent or own the premises that the business occupies?</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer?.rent} width="100%" onChange={e => {setCustomer({...customer,rent:e.target.value})}} /></Flex></Flex>
    <Flex><Label for="name">Years at Location</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer.yearsat} width="100%" onChange={e => {setCustomer({...customer,yearsat:e.target.value})}} /></Flex></Flex>
  
</Flexed>

<Flexed padding="10px 0px 0px 0px">
    <Flex flex="3" ><Label for="name">2. Has your company or any company that any officer, partner, member or owner been associated with as an officer, partner, member, or
owner ever had credit with us before?</Label></Flex><Flex><Selectbox style={{display:"inline-block"}} value={customer?.creditbefore} onChange={e => {setCustomer({...customer,creditbefore:e.target.value})}}><option value="No">No</option>
	<option value="Yes">Yes</option></Selectbox></Flex>
</Flexed>
<Flexed padding="20px 0px 0px 0px">
    <Flex ><Label for="name">* If Yes, under what name?</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer?.creditbeforename} width="100%" onChange={e => {setCustomer({...customer,creditbeforename:e.target.value})}} /></Flex></Flex>
   
</Flexed>
<br /><br /><br /><br />
<Flexed>
    <Flex >
By signing below, I certify that I have the authority to bind {customer.name} to this agreement, and 
that I agree to the terms of sale for PLS Print. I also agree and accept that the credit limit and credit terms may be changed or 
withdrawn at the sole discretion of PLS Print. PLS Print shall include creditor subsidiaries, related companies, and assigns.<br /><br />
Our standard terms are net 30 days from date of invoice. Credit limit will be determined upon receipt and review of completed
application. The information given herein is offered as part of a request by the applicant for an extension of credit for 
commercial business use. The information provided is represented by the applicant to be true, correct and complete. The 
Applicant authorizes PLS Print to investigate all credit references and other sources pertaining to our credit and financial 
responsibility. The undersigned authorizes its banks and trade creditors to provide PLS Print with complete information for the
purpose of credit evaluation. The applicant understands that all past due balances will be subject to a 3.0% per month finance 
charge. The applicant further agrees to pay a$150 collection fee in the event of default, and attorney fees if the account is 
placed with a collection agency or attorney.

</Flex>
</Flexed>
<br /><br />
<Flexed padding="10px 0px 0px 0px">
   
    <Flex ><Label for="name">Signature:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={customer?.signame} width="100%" onChange={e => {setCustomer({...customer,signame:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Title:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={customer?.sigtitle} width="100%"  onChange={e => {setCustomer({...customer,sigtitle:e.target.value})}}/></Flex></Flex>
    <Flex flex=".5"><Label for="name">Date:</Label><Flex bottom="9px"><Datepick autocomplete="new-user-email" type="text"  width="100%"  selected={sigdate} onChange={(date) => setSigdate(date)}/></Flex></Flex>
</Flexed>

</form>

    </div>
    
    
 :<span style={{position:"relative",height:"100vh",width:"100%",margin:"0px auto",fontSize:"3rem",textAlign:"center",alignItems:"center"}}>Nothing to display</span>}
    </div>
  )
}

export default Credit