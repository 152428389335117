import {useEffect,useState} from 'react'
import ReactConfetti from 'react-confetti'
import { Flex, Flexed } from '../utilities/Shared'

const Confetti = () => {
const [wd, setWd] = useState({width:window.innerWidth, height:window.innerHeight})
const sizeDetect = () => {
    setWd({width:window.innerWidth, height:window.innerHeight});
}

useEffect(() => {
window.addEventListener('resize',sizeDetect);
return () => {
    window.removeEventListener('resize',sizeDetect);   
}
},[wd])


  return (
    <>
    <ReactConfetti 
    width={wd.width}
    height={wd.height}
    style={{zIndex:"100000"}}
    />
    <Flexed padding="0px 25%" width="100%" height="100vh" fontSize="calc(2.2rem + .2vw)" fontWeight="bold" alignItems="center" justifyContent="center" textAlign="center" >
        <Flex> Thank you taking the time to fill out this information.<br /> We look forward to servicing your needs and<br /> appreciate your business</Flex>
    </Flexed>
    </>
  )
}

export default Confetti