import React, { createRef, useRef, useState } from 'react'
import { Button, Flex, Flexed, Img, Input } from '../utilities/Shared'
import axios from 'axios'

const Fileupload = ({customerid,setFormdata,setImage}) => {
    
  const form = useRef()
  const forminfo = createRef()
   
  
  const [file, setFile] = useState("")

  function handleChange(event) {
    setFile(event.target.files[0])
    const formData = new FormData();
    formData.append('file', event.target.files[0]);
    formData.append('name', 'pams');
  
    setFormdata(formData)
      var reader = new FileReader();
reader?.readAsDataURL(event.target.files[0])
reader.onload = () => {
   
  setImage(reader.result)
}
reader.onerror = error => {
    console.log("error",error)
}

    
  }










const clearfile = () => {
  setFile(null)
form.current.reset()
}


  return (
    <div className="App">
        <form style={{border:"1px solid red",textAlign:"left",fontSize:"calc(1.2rem + .2vw)"}} >
          <input style={{border:"1px solid red",textAlign:"left",fontSize:"calc(.8rem + .2vw)"}} type="file"  onChange={handleChange} name="avatar" />
       
        </form>
    </div>
  )
}

export default Fileupload