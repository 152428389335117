export const defaultOwners = {
address1:"",
address2:"",
city:"",
email:"",
name:"",
ownerpercent:"",
phone:"",
title:"",
zip:''
}

export const defaultBanks = {
    account:"",
    accttype:"",
    address1:"",
    address2:"",
    city:"",
    contact:"",
    email:"",
    name:"",
    phone:"",
    zip:""
    }

    export const defaultRefers = {
        address1:"",
        address2:"",
        city:"",
        contact:"",
        email:"",
        name:"",
        phone:"",
        zip:""
        }