import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Flexed, Img, Input, Label, PhoneNumber, Selectbox} from '../utilities/Shared'
import States from '../utilities/States'
import axios from 'axios'
import LOGO from '../Assets/plsprint.png'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'react-moment';
import { v4 as uuidv4 } from 'uuid';
import Fileupload from '../components/Fileupload'




const Information = () => {

   

const [customer, setCustomer] = useState({})
const [location, setLocation] = useState({state:"FL",billing:"No"})
const [locations, setLocations] = useState([])
const [billingaddress, setBillingaddress] = useState({})
const [contact, setContact] = useState({invoices:'No'})
const [contacts, setContacts] = useState([])
const [billingcheck, setBillingcheck] = useState(false)
const date = new Date().toLocaleDateString()
const [taxexempt, setTaxexempt] = useState()
const [file, setFile] = useState(null)
const [informationid, setInformationid] = useState(null)
const [corparate, setCorparate] = useState('')
const [urlinfo] = useSearchParams()
const [signature, setSignature] = useState(false)
const [req, setReq] = useState(true)
const [newfile, setNewfile] = useState()
const [formdata, setFormdata] = useState()
const [image, setImage] = useState("")


const nav = useNavigate()
const defaultbilling = {
    name:null,
    dba:null,
    address1:null,
    address2:null,
    city:null,
    state:null,
    zip:null
}
const defaultlocation = {
        address1:"",
        address2:"",
        billing:"",
        city:"",
        state:"FL",
        name:"",
        zip:"",
        itemid:""
}
const defaultcontact = {
    name:"",
    title:"",
    email:"",
    officephone:"",
    cell:"",
    invoices:"",
    contactid:""
}
useEffect(() => {


const getcustomer = () => {
    //axios.get(`http://localhost:5003/customers/item/${urlinfo.get('cid')}`)
 axios.get(`https://highgroundapi.herokuapp.com/customers/item/${urlinfo.get('cid')}`)
    .then(res => {
  
            setCustomer({...res.data,custstate:"FL",taxexempt:"No",paymentmethod:"",unders:"",overs:"",issusespos:"",signature:"",dr13:null});setInformationid(res.data.formid);setvalues(res.data)
    })
    .catch(err => console.log(err))
}


getcustomer()
},[urlinfo.get('cid')])

useEffect(() => {
customer.paymentmethod > "" && customer.unders > "" && customer.overs > "" && customer.issusespos > "" ? setReq(false) : setReq(true)
},[customer])

console.log(customer)

const setvalues = (customer) => {

setLocations(customer.address)
setContacts(customer.contacts)

}

useEffect(() => {

if(billingcheck === true){
  return  setBillingaddress({name:customer.name,dba:customer.dba,address1:location.address1,address2:location.address2,city:location.city,state:location.state,zip:location.zip})
}else{
    return setBillingaddress(defaultbilling)
}

},[billingcheck,customer])

useEffect(() => {
taxexempt === "No" && setFile(null)
},[taxexempt])

const config = {
    headers: {
      //'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin':'*' 
    }
  };    



const addlocation = (e) => {

   let templocations = locations
   location.itemid = uuidv4();

    templocations.push(location)
    setLocations(templocations)
    setLocation(defaultlocation)
}


const addcontact = (e) => {
e.preventDefault()
    let tempcontacts = contacts
    contact.contactid = uuidv4();
 
     tempcontacts.push(contact)
     setContacts(tempcontacts)
     setContact(defaultcontact)
 }

 const handleupload = async() => {
    await axios.post('https://highgroundapi.herokuapp.com/images/add/tax',file)
    .then(res => {})
    .catch(err => console.log(err))
  }



  const saveform = (e) => {
e.preventDefault()

const saveit = async() => {

    let info = {
        id:customer._id,
    name:customer.name,
    website:customer.website,
    billingterms:customer.billingterms,
    taxexempt:customer.taxexempt,
    notes:customer.notes,
    salesrep:customer.salesrep,
    custrep:customer.custrep,
    pros:customer.pros,
    dba:customer.dba,
    overs:customer.overs,
    unders:customer.unders,
    paymentmethod:customer.paymentmethod,
    issusespos:customer.issusespos,
    type:customer.type,
    fein:customer.fein,
    custstate:customer.custstate,
    address:locations,
    contacts,
    signature:customer.signature,
    signaturedate:new Date(),
    dr13:customer.dr13
    }
    //await axios.post(`https://highgroundapi.herokuapp.com/customers/update/all`,info)
    await axios.post(`https://highgroundapi.herokuapp.com/customers/update/all`,info)
    .then(res => {
        uploadit()

    }) 
     .catch(err => console.log(err))
}
saveit()
  }


  const uploadit = async() => {
  
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/imageupload.php`,formdata,config)
    .then(res => {
  setimagetocustomer(res.data)
      
    })
    .catch(err => console.log(err))
  }


  const setimagetocustomer = async(resdata) => {

    let data = {
      id:customer._id,
    field:"dr13",
  value:resdata
    }

    await axios.post(`https://highgroundapi.herokuapp.com/customers/update/field`,data)
    .then(res => {
     sendEmail()

    })
    .catch(err => console.log(err))
  }


const sendEmail = async() => {
   let uu = new Date().toLocaleDateString()
   let time = new Date().toLocaleTimeString()
    let t = {

        to:[customer.salesrep?.workEmail,"bryan@plsprint.com","jane@plsprint.com",customer.custrep?.workEmail],//customer.salesrep?.workEmail,"jane@plsprint.com",
        subject:`[HighGround] NEW CUSTOMER FORM COMPLETED - ${customer.name}`,
        message:`<br /><br />NEW CUSTOMER FORM COMPLETED<br /><br /><br />
        ${uu} - ${time}<br /><br /><br />
        Company Name: ${customer.name}
        <br /><br /><br />
        Company DBA: ${customer.dba}
        `
    }
   await axios.post(`https://highgroundapi.herokuapp.com/email/send`,t)
       // await axios.post(`http://localhost:5003/email/send`,t)
    .then(res => resetformid())
      .catch(err => {console.log(err);})     

}

const resetformid = async() => {
    console.log('hi')
    let t = {
        id:customer._id,
        field:'formid',
        value:null
    }
    await axios.post(`https://highgroundapi.herokuapp.com/customers/update/field`,t)
    .then(res => {nav('/thanks',{ replace: true })})
      .catch(err => {console.log(err);})    
  }



  const setsig = (e) => {
   
    setSignature(e.target.checked)
  }



  return (
    
    <div style={{background:"rgb(0,0,0,0.6)",position:"relative",width:"100%",height:"100%",padding:"0px 0px 50px 0px"}} >
    {customer._id === urlinfo.get('cid') && urlinfo.get('qid') === customer.formid? 
    <div className="information" >

    <form id="some_id" method="post" enctype="multipart/form-data">
<Flexed marginTop="20px">
    <Flex flex=".5"><Img src={LOGO} width="50%" /></Flex>
    <Flex style={{lineHeight:"60px"}} fontSize="calc(1.1rem + .2vw)" fontWeight="bold" textAlign="center">Customer Information Form</Flex>
</Flexed>
<Flexed marginTop="15px">
    <Flex  padding="10px 0px 0px 0px" fontSize="calc(.7rem + .2vw)">We ask that every new customer of PLS Print complete the following information for. All information submitted is confidential and for our records only.</Flex>
</Flexed >

<Flexed padding="10px 0px 0px 0px" marginTop="15px">
    <Flex><Label>Todays Date: {date}</Label></Flex>
    <Flex ><Label>Sales Rep: {customer.salesrep?.firstName+" "+customer.salesrep?.lastName}</Label></Flex>
    <Flex flex=".5"></Flex>
</Flexed>

<Flexed padding="30px 0px 10px 0px">
    <Flex ><Label for="name">Company Name: {customer?.name} </Label></Flex><Flex flex=".3"></Flex>
</Flexed>
<Flexed padding="5px 0px 5px 0px">
    <Flex ><Label for="name">Company DBA:&nbsp;&nbsp;</Label><Flex bottom="9px"><Input type="text" onChange={e => {setCustomer({...customer,dba:e.target.value})}} fontSize="calc(.8rem + .3vw)" value={customer?.dba} width="100%"  /></Flex></Flex>
    <Flex flex=".3"></Flex>
</Flexed>

<Flexed marginTop="20px">
    <Flex fontSize="calc(.8rem + .2vw)" fontWeight="bold" >LOCATIONS</Flex>
</Flexed>
<div >
<Flexed bgColor="#78BE20" padding="3px" bgColor="#8798A9" color="white">
<Flex flex="2.5">Name</Flex>
<Flex flex="2.5">Address 1</Flex>
<Flex flex="2.5">Address 2</Flex>
<Flex flex="1.3">City</Flex>
<Flex flex=".5">State</Flex>
<Flex flex=".5">Zip</Flex>

</Flexed>

{locations?.map(location => {
  return  <Flexed style={{border:"1px solid rgb(0,0,0,.3)"}} padding="3px" fontSize="calc(.7rem + .2vw)" gap="5px">
<Flex flex="2.5">{location?.name}</Flex>
<Flex flex="2.5">{location?.address1}</Flex>
<Flex flex="2.5">{location?.address2}</Flex>
<Flex  flex="1.3">{location?.city}</Flex>
<Flex  flex=".5">{location?.state}</Flex>
<Flex flex=".5">{location?.zip}</Flex>


</Flexed>
})

}
</div>
<div style={{border:"1px solid lightgrey",position:"relative",top:"8px"}}>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Location Name:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={location?.name} width="100%"  onChange={e => {setLocation({...location,name:e.target.value})}}/></Flex></Flex>
    <Flex ><Label for="name">Address 1:</Label><Flex bottom="9px"><Input type="text" autocomplete="new-user-email" value={location?.address1} width="100%" onChange={e => {setLocation({...location,address1:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Address 2:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={location?.address2} width="100%"  onChange={e => {setLocation({...location,address2:e.target.value})}}/></Flex></Flex>

</Flexed>

<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">City:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={location?.city} width="100%" onChange={e => {setLocation({...location,city:e.target.value})}} /></Flex></Flex>
    <Flex><Label for="name">State:</Label><Flex bottom="9px"><States value={location?.state} onChange={e => {setLocation({...location,state:e.target.value})}}/></Flex></Flex>
    <Flex flex=".5"><Label for="name">Zip:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={location?.zip} width="100%" onChange={e => {setLocation({...location,zip:e.target.value})}} /></Flex></Flex>
</Flexed>
<Flexed margin="0px 0px 10px 0px" padding="10px 0px"><Flex><Button type="button" disabled={location?.name === "" || !location?.name ? true : false} onClick={addlocation}>Add Location</Button></Flex></Flexed>
</div>



<Flexed marginTop="30px">
    <Flex fontSize="calc(.8rem + .2vw)" fontWeight="bold" >CONTACTS</Flex>
</Flexed>
<div >
<Flexed bgColor="#78BE20" padding="3px" bgColor="#8798A9" color="white">
<Flex flex="2">Name</Flex>
<Flex flex="2">Title</Flex>
<Flex flex="2">Email</Flex>
<Flex flex="1.5">Office Phone</Flex>
<Flex flex="1.5">Mobile Phone</Flex>
<Flex>Invoices</Flex>

</Flexed>

{contacts?.map(contact => {
  return  <Flexed style={{border:"1px solid rgb(0,0,0,.3)"}} padding="3px" fontSize="calc(.7rem + .2vw)" >
<Flex  flex="2">{contact?.name}</Flex>
<Flex  flex="2">{contact?.title}</Flex>
<Flex  flex="2">{contact?.email}</Flex>
<Flex flex="1.5">{contact?.officephone}</Flex>
<Flex flex="1.5">{contact?.cell}</Flex>
<Flex >{contact?.invoices}</Flex>
</Flexed>
})

}
</div>
<div style={{border:"1px solid lightgrey",position:"relative",top:"8px"}}>
<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Name:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={contact?.name} width="100%" onChange={e => {setContact({...contact,name:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Title:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={contact?.title} width="100%" onChange={e => {setContact({...contact,title:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Email:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" type="email" value={contact?.email} width="100%" onChange={e => {setContact({...contact,email:e.target.value})}} /></Flex></Flex>

</Flexed>

<Flexed padding="10px 0px 0px 0px">
    <Flex ><Label for="name">Office Phone:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={PhoneNumber(contact?.officephone)} width="100%" onChange={e => {setContact({...contact,officephone:e.target.value})}} /></Flex></Flex>
    <Flex ><Label for="name">Mobile Phone:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text" value={PhoneNumber(contact?.cell)} width="100%" onChange={e => {setContact({...contact,cell:e.target.value})}} /></Flex></Flex>
   <Flex flex=".5"><Label for="name">Invoices :</Label><Flex bottom="9px"><Selectbox value={contact?.invoices} onChange={e => {setContact({...contact,invoices:e.target.value})}}><option value="No">No</option>
	<option value="Yes">Yes</option></Selectbox></Flex></Flex>
</Flexed>
<Flexed padding="7px 0px"><Flex><Button  type="button" disabled={contact?.name === "" || !contact?.name ? true : false} onClick={e => addcontact(e)}>Add Contact</Button></Flex></Flexed>

</div>


<Flexed marginTop="30px">
    <Flex fontSize="calc(.8rem + .2vw)" fontWeight="bold" >CORPORATE INFO</Flex>
</Flexed>
<Flexed padding="10px 0px 0px 0px">
    <Flex flex="1.3" ><Label for="name">Business Type:</Label><Flex bottom="9px"><Selectbox onChange={e => setCustomer({...customer,type:e.target.value})} value={customer?.type}>
    <option value="">Select Type</option>
        <option value="Corporation">Corporation</option>
	<option value="Partnership">Partnership</option>
    <option value="LLC">LLC</option>
	<option value="Sole Proprietorship">Sole Proprietorship</option>
    </Selectbox></Flex></Flex>
    <Flex ><Label for="name">FEIN Number:</Label><Flex bottom="9px"><Input autocomplete="new-user-email" type="text"  width="100%" onChange={e => setCustomer({...customer,fein:e.target.value})} /></Flex></Flex>
    <Flex ><Label for="name">State:</Label><Flex bottom="9px"><States value={customer?.custstate} onChange={e => setCustomer({...customer,custstate:e.target.value})}/></Flex></Flex>
  
</Flexed>
<Flexed padding="10px 0px 0px 0px">
<Flex ><Label for="name">Tax Exempt:</Label><Flex bottom="9px"><Selectbox onChange={e => setCustomer({...customer,taxexempt:e.target.value})} value={customer.taxexempt}><option value="No">No</option>
	<option value="Yes">Yes</option></Selectbox></Flex></Flex>
</Flexed>
{
     customer.taxexempt === 'Yes' &&  <>
    <Flexed padding="20px 0px ">
        <Flex> <div style={{fontSize:"calc(.9rem + .2vw)",color:"rgb(255,0,0,.6)",fontWeight:"bold"}}> 
    <Fileupload setNewfile={setNewfile} setImage={setImage} setFormdata={setFormdata} file={file} setFile={setFile} customerid={customer._id} style={{marginTop:"-10px"}}/></div></Flex>
        <Flex> <iframe src={image} title="none" style={{height:"150px"}}></iframe></Flex>
    </Flexed>

   </>
}

<Flexed padding="10px 0px 0px 0px">
    <Flex flex="1.5" ><Label for="name">Your preferred Method of Payment.</Label><Flex bottom="9px"><Selectbox onChange={e => setCustomer({...customer,paymentmethod:e.target.value})} value={customer?.paymentmethod} style={{border:customer.paymentmethod <= "" ? "1px solid red" : ""}} required>
    <option value="">Select Method</option>
        <option value="Credit Card">Credit Card</option>
	<option value="Check">Check</option>
    <option value="ACH/Wire">ACH/Wire</option>
	<option value="Cash">Cash</option>
    </Selectbox>{customer.paymentmethod <= "" && <>&nbsp;&nbsp;&nbsp; <span style={{color:"red",fontSize:"calc(2rem + .2vw)",position:"relative",top:"7px"}}>*</span></>}</Flex></Flex>
</Flexed>
<Flexed padding="10px 0px 0px 0px">
    <Flex flex="1.5" ><Label for="name">Will you be issuing a purchase order on every job?</Label><Flex bottom="9px"><Selectbox onChange={e => setCustomer({...customer,issusespos:e.target.value})} value={customer?.issusespos} style={{border:customer.issusespos <= "" ? "1px solid red" : ""}} required>
    <option value="">Select Option</option>
    <option value="No">No</option>
	<option value="Yes">Yes</option>
    </Selectbox>{customer.issusespos <= "" && <>&nbsp;&nbsp;&nbsp; <span style={{color:"red",fontSize:"calc(2rem + .2vw)",position:"relative",top:"7px"}}>*</span></>}</Flex></Flex>
</Flexed>
<Flexed padding="10px 0px 0px 0px">
    <Flex >Printing industry standard for over-runs and under-runs is 5%</Flex>
</Flexed>

<Flexed padding="10px 0px 0px 0px">
    <Flex flex="1.5" ><Label for="name">Will you accept under-runs?</Label><Flex bottom="9px"><Selectbox onChange={e => setCustomer({...customer,unders:e.target.value})} value={customer?.unders} style={{border:customer.unders <= "" ? "1px solid red" : ""}} required>
    <option value="">Select Option</option>
    <option value="No">No</option>
	<option value="Yes">Yes</option>
    </Selectbox>{customer.unders <= "" && <>&nbsp;&nbsp;&nbsp; <span style={{color:"red",fontSize:"calc(2rem + .2vw)",position:"relative",top:"7px"}}>*</span></>}</Flex></Flex>
</Flexed>

<Flexed padding="10px 0px 0px 0px">
    <Flex flex="1.5" ><Label for="name">Will you accept over-runs?</Label><Flex bottom="9px"><Selectbox onChange={e => setCustomer({...customer,overs:e.target.value})} value={customer?.overs} style={{border:customer.overs <= "" ? "1px solid red" : ""}} required>
    <option value="">Select Option</option>
    <option value="No">No</option>
	<option value="Yes">Yes</option>
    </Selectbox>{customer.overs <= "" && <>&nbsp;&nbsp;&nbsp; <span style={{color:"red",fontSize:"calc(2rem + .2vw)",position:"relative",top:"7px"}}>*</span></>}</Flex></Flex>
</Flexed>


<div style={{marginTop:"20px"}}>
    <span style={{fontSize:"1rem + .2vw",fontWeight:"bold",display:"block"}}>Terms of Business between Precision Litho Service, Inc. and Customer</span>
First time customers agree to pay for printing on C.O.D. terms. Taxes are not included in quoted prices, and will be added on at the time of final invoice. Claims for 
defect, shortage or damages must be made no later than 10 calendar days from delivery date. If no claim is made, the job is considered accepted, and the customer 
acknowledges that the printers' performance has satisfied all terms, conditions and specifications. If payment is not made within terms, the printer reserves the right to 
initiate standard collection procedures. Should no payment be made, the customer shall be liable for all attorney’s fees and collection costs incurred.<br /><br />
No sales tax exemption shall be granted without a signed current Exemption Certificate on file at the time the order is placed. The business named on the certificate 
must be the business billed. Taxes not paid to the printer, when no certificate has been submitted, shall remain the responsibility of the customer.<br /><br />
I have read the terms and conditions above and confirm that I am a representative of the above specified company, and agree to pay 
within terms
</div>


<div className="agree">
    <Flexed>
    <Flex flex=".25" fontSize="calc(.7rem + .2vw)"><Input type="checkbox" onChange={e => setsig(e)} disabled={req}/> <Flex> I agree</Flex></Flex>
   
    <Flex flex="3" fontSize="calc(.9rem + .2vw)">
        <div style={{whiteSpace:"pre-wrap",wordBreak:"break-word"}}>
   * By digitally signing this form, I agree that the information I have provided is accurate. As a representative of the above-mentioned company, I agree to the terms and conditions stated above.
   </div>   
        
      </Flex>
    </Flexed>
    <Flexed marginTop="20px">
    <Flex ><Label for="name">Signature</Label><Flex bottom="9px"><Input style={{border:signature ? "1px solid #7ABB43" : ""}}  onChange={e => setCustomer({...customer,signature:e.target.value})} value={customer?.signature} autocomplete="new-user-email" type="text"  width="100%" disabled={signature ? false : true} /></Flex></Flex>
      
      
    </Flexed>
</div>





























<Flexed>
    <Flex>
       {customer.signature > "" && <Button  onClick={e => saveform(e)}>Save</Button>}
    </Flex>
</Flexed>
</form>
    </div>
    
    
 :<Flexed bgColor="white">
    <Flex><span style={{backgroud:"white",position:"relative",height:"100vh",width:"100%",margin:"0px auto",fontSize:"2.5rem",textAlign:"center",alignItems:"center"}}>This page has expired. Please contact PLS Print to generate a new form.  </span></Flex>
    </Flexed>}
    </div>
  )
}

export default Information













